import { HashRouter, Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Encabezado from "./componentes/general/Encabezado";
import ClienteDetalles from "./paginas/ClienteDetalles";
import ClientesLista from "./paginas/ClientesLista";
import Home from "./paginas/Home";
import InicioSesion from "./paginas/InicioSesion";
import ProductoDetalles from "./paginas/ProductoDetalles";
import ProductosLista from "./paginas/ProductosLista";
import RealizarVenta from "./paginas/RealizarVenta";
import RegistrarCliente from "./paginas/RegistrarCliente";
import RegistrarProducto from "./paginas/RegistrarProducto";
import VentaDetalles from "./paginas/VentaDetalles";
import VentasLista from "./paginas/VentasLista";
import UsuariosLista from "./paginas/UsuariosLista";
import UsuarioDetalles from "./paginas/UsuarioDetalles";
import RegistrarUsuario from "./paginas/RegistrarUsuario";
import CuentaDetalles from "./paginas/CuentaDetalles";
import { useSelector } from "react-redux";
import GlobalStyles from "./GlobalStyles";
import AjusteInventarioLista from "./paginas/AjusteinventarioLista";
import RealizarAjusteInventario from "./paginas/RealizarAjusteInventario";
import { Error404 } from './paginas/Error404';
import RequireAuth from "../src/componentes/general/RequireAuth";

// Hola mundo
function App() {
  // Obtener informacion del usuario desde el Redux store
  const usuarioInfo = useSelector((state) => state.usuarioInfo);
  const { token } = usuarioInfo;

/*  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [token]);*/

  return (
    <div className="App">
      <GlobalStyles />
      <HashRouter>
        <Encabezado />
        <Routes>
            {/* Home */}
            <Route path="/" element={
              <RequireAuth> 
                <Home />
              </RequireAuth>
            } />

            {/* Productos */}
            <Route path="/productos" element={
              <RequireAuth>
                <ProductosLista />
              </RequireAuth>
            } />

            <Route path="/productos/:id" element={
              <RequireAuth>
                <ProductoDetalles />
              </RequireAuth>
            } />
            
            <Route path="/ajuste-inventario/:id" element={
              <RequireAuth>
                <RealizarAjusteInventario />
              </RequireAuth>
            }/>

            <Route path="/registrar-producto" element={
              <RequireAuth>
                <RegistrarProducto />
              </RequireAuth>  
            }/>

            {/* Clientes */}
            <Route path="/clientes" element={
              <RequireAuth>
                <ClientesLista />
              </RequireAuth>
            } />
            
            <Route path="/clientes/:id" element={
              <RequireAuth>
                <ClienteDetalles />
              </RequireAuth>
            } />

            <Route path="/registrar-cliente" element={
              <RequireAuth>
                <RegistrarCliente />
              </RequireAuth>    
            } />

            {/* Ventas */}
            <Route path="/ventas" element={
              <RequireAuth>
                <VentasLista />
              </RequireAuth>    
            } />

            <Route path="/ventas/:id" element={
              <RequireAuth>
                <VentaDetalles />
              </RequireAuth>    
            } />

            <Route path="/realizar-venta" element={
              <RequireAuth>
                <RealizarVenta />
              </RequireAuth>    
            } />

            {/* Usuarios */}
            <Route path="/usuarios" element={
              <RequireAuth>
                <UsuariosLista />
              </RequireAuth>    
            } />

            <Route path="/usuarios/:id" element={
              <RequireAuth>
                <UsuarioDetalles />
              </RequireAuth>    
            } />

            <Route path="/registrar-usuario" element={
              <RequireAuth>
                <RegistrarUsuario />
              </RequireAuth>    
            } />

            {/* Cuenta */}
            <Route path="/cuenta" element={
              <RequireAuth>
                <CuentaDetalles />
              </RequireAuth>    
            } />

            <Route path="/ajuste-inventarios" element={
              <RequireAuth>
                <AjusteInventarioLista />
              </RequireAuth>    
            } />

            {/* Inicio de sesion */}
            <Route path="/login" element={<InicioSesion />} />   
            {/* Error 404 */}
            <Route path="*" element={
              <RequireAuth>
                <Error404/>
              </RequireAuth>    
            } />     
          </Routes>
        <Toaster />
      </HashRouter>
    </div>
  );
}

export default App;
