import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { registrarCliente } from "../actions/clienteActions";
import { RESET_CLIENTE_REGISTRAR } from "../constantes/clienteConstantes";
import { useForm } from "react-hook-form";
import VentanaFormularioPrecios from "../componentes/RegistrarCliente/VentanaFormularioPrecios";
import {
  StyledBoton,
  StyledCol,
  StyledContainer,
  StyledFormGroup,
  StyledRow,
} from "./styles/RegistrarCliente.styles";
import VentanaFormularioRuta from "../componentes/RegistrarCliente/VentanaFormularioRuta";
import { useProductosCliente } from "../lib/hooks/useProductosCliente";
import { pedirProductosLista } from "../actions/productoActions";
import { useRutasCliente } from "../lib/hooks/useRutasCliente";
import { pedirRutasParaClienteLista } from "../actions/rutaActions";
import { crearNuevosPreciosCliente } from "../lib/utilis/clientes";
import { useRef } from "react";

const RegistrarCliente = () => {
  // Funcion para disparar las acciones
  const dispatch = useDispatch();
  // Funcion para navegar en la pagina
  const navigate = useNavigate();

  // Obtener referencias de los botones
  const rutasRef = useRef(null);
  const modificarRef = useRef(null);
  const registrarRef = useRef(null);

  // Estado deshabilitacion de boton
  const [disabledState, setDisabledState] = useState(false);

  // Obtener lista de productos del Redux
  const productoLista = useSelector((state) => state.productoLista);
  const { productos } = productoLista;
  const rutaLista = useSelector((state) => state.rutaLista);
  const { rutas: rutasRedux } = rutaLista;
  // Obtener el estado registrar cliente del Redux
  const clienteRegistrar = useSelector((state) => state.clienteRegistrar);
  const {
    loading: loadingRegistrar,
    success: successRegistrar,
    error: errorRegistrar,
  } = clienteRegistrar;

  // Custom Hook para precios de productos del cliente
  const {
    productosCliente,
    mostrarProductos,
    setProductosCliente, // idealmente no deberia sacar esta funcion del custom hook, es mojor usar una funcion que use adentro a esta funcion, pero parece que en este caso es mas sencillo hacer esto
    manejarCambioPrecio,
    setMostrarProductos,
  } = useProductosCliente();

  // Custom Hook para rutas  del cliente
  const {
    rutas,
    modificarDayIds,
    modificarRuta,
    mostrarRutas,
    setMostrarRutas,
  } = useRutasCliente(rutasRedux);

  // useForm para validar formulario
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // useEffect para mostrar las alertas de validacion del formulario
  useEffect(() => {
    if (errors.ciudad) {
      toast.dismiss();
      toast.error(errors.ciudad.message);
    }

    if (errors.numero) {
      toast.dismiss();
      toast.error(errors.numero.message);
    }

    if (errors.calle) {
      toast.dismiss();
      toast.error(errors.calle.message);
    }

    if (errors.telefono) {
      toast.dismiss();
      toast.error(errors.telefono.message);
    }

    if (errors.nombre) {
      toast.dismiss();
      toast.error(errors.nombre.message);
    }
  }, [
    errors.nombre,
    errors.telefono,
    errors.calle,
    errors.numero,
    errors.ciudad,
  ]);

  // useEffect para mostrar las alertas de registro cliente
  useEffect(() => {
    let toastId;
    if (loadingRegistrar) {
      toastId = toast.loading("Registrando cliente");
    }

    if (successRegistrar) {
      toast.dismiss(toastId);
      toast.success("Cliente registrado");
      // Reset cliente registrar para que no se ejecute este bloque de codigo cada vez que entra a registrar cliente
      dispatch({ type: RESET_CLIENTE_REGISTRAR });
      navigate("/clientes");
    }

    if (errorRegistrar) {
      toast.dismiss(toastId);
      toast.error("Error al registrar cliente");
    }
  }, [successRegistrar, errorRegistrar, loadingRegistrar, navigate, dispatch]);

  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  //  useEffect para obtener productos del Redux Store
  useEffect(() => {
    if (!productos) {
      dispatch(pedirProductosLista());
    } else {
      // Esto permite que el nuevo cliente tenga el precio por defecto de todos los productos en la base de datos
      setProductosCliente(productos);
    }
  }, [productos, dispatch, setProductosCliente]);

  // useEffect para cargar la lista de rutas
  useEffect(() => {
    if (!rutasRedux) {
      dispatch(pedirRutasParaClienteLista());
    }
  }, [dispatch, rutasRedux]);

  // Funcion para registrar cliente
  const manejarRegistrarCliente = (data) => {
    setDisabledState(!disabledState);
    // Esta funcion permite crear un array de precios con el formato que recibe el backend
    const nuevosPreciosCliente = crearNuevosPreciosCliente(productosCliente);

    const rutasIds = rutas.selectedIds ? rutas.selectedIds : [];

    console.log(data);

    dispatch(
      registrarCliente({
        NOMBRE: data.nombre,
        CONTACTO: data.contacto,
        TELEFONO: data.telefono,
        CORREO: data.correo,
        TIPO_PAGO: data.tipo_pago,
        direccion: {
          CALLE: data.calle,
          NUMERO: data.numero,
          COLONIA: data.colonia,
          CIUDAD: data.ciudad,
          MUNICIPIO: data.municipio,
          CP: data.codigoPostal !== "" ? data.codigoPostal : null,
        },
        preciosCliente: nuevosPreciosCliente,
        OBSERVACIONES: data.observaciones,
        rutasIds,
      })
    );
  };

  return (
    productos &&
    rutasRedux && (
      <>
        <StyledContainer fluid>
          <h1>Registrar cliente</h1>

          <Form onSubmit={handleSubmit(manejarRegistrarCliente)}>
            <StyledRow>
              <StyledCol md={4}>
                {/* Nombre */}

                <StyledFormGroup controlId="nombre">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    {...register("nombre", {
                      required: "Por favor, introduce el nombre del cliente",
                    })}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Contacto */}
                <StyledFormGroup controlId="contacto">
                  <Form.Label>Nombre Contacto (Opcional)</Form.Label>
                  <Form.Control
                    {...register("contacto")}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Telefono */}
                <StyledFormGroup controlId="telefono">
                  <Form.Label>Telefono</Form.Label>
                  <Form.Control
                    {...register("telefono", {
                      required: "Por favor, introduce el teléfono del cliente",
                    })}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Correo */}
                <StyledFormGroup controlId="correo">
                  <Form.Label>Correo (Opcional)</Form.Label>
                  <Form.Control
                    {...register("correo")}
                    type="email"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>
                <div>
                  <StyledBoton
                    color="green"
                    disabled={false}
                    ref={rutasRef}
                    onFocus={() => rutasRef.current.blur()}
                    onClick={() => setMostrarRutas(true)}
                  >
                    Seleccionar Rutas
                  </StyledBoton>
                </div>
              </StyledCol>
              <StyledCol md={4}>
                {/* Tipo de pago */}
                <StyledFormGroup controlId="tipo_pago">
                  <Form.Label>Tipo de pago</Form.Label>
                  <Form.Control {...register("tipo_pago")} as="select">
                    <option value="EFECTIVO">EFECTIVO</option>
                    <option value="CREDITO">CREDITO</option>
                  </Form.Control>
                </StyledFormGroup>
                {/* Calle */}
                <StyledFormGroup controlId="calle">
                  <Form.Label>Calle</Form.Label>
                  <Form.Control
                    {...register("calle", {
                      required: "Por favor, introduce la calle del cliente",
                    })}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Numero */}
                <StyledFormGroup controlId="numero">
                  <Form.Label>Número Dirección</Form.Label>
                  <Form.Control
                    {...register("numero", {
                      required: "Por favor, introduce el número en la calle",
                    })}
                    type="text"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Colonia */}
                <StyledFormGroup controlId="colonia">
                  <Form.Label>Colonia (Opcional)</Form.Label>
                  <Form.Control
                    {...register("colonia")}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>
                <div>
                  <StyledBoton
                    color="green"
                    disabled={false}
                    ref={modificarRef}
                    onFocus={() => {
                      modificarRef.current.blur();
                    }}
                    onClick={() => setMostrarProductos(true)}
                  >
                    Modificar precios
                  </StyledBoton>
                </div>
              </StyledCol>
              <StyledCol md={4}>
                {/* Ciudad */}
                <StyledFormGroup controlId="ciudad">
                  <Form.Label>Ciudad</Form.Label>
                  <Form.Control
                    {...register("ciudad", {
                      required: "Por favor, introduce la ciudad",
                    })}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Municipio */}
                <StyledFormGroup controlId="municipio">
                  <Form.Label>Municipio (Opcional)</Form.Label>
                  <Form.Control
                    {...register("municipio")}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Codigo postal */}
                <StyledFormGroup controlId="codigoPostal">
                  <Form.Label>C.P (Opcional)</Form.Label>
                  <Form.Control
                    {...register("codigoPostal")}
                    type="number"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Observaciones */}
                <StyledFormGroup controlId="colonia">
                  <Form.Label>Observaciones (Opcional)</Form.Label>
                  <Form.Control
                    {...register("observaciones")}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>
                <div>
                  <StyledBoton
                    type="submit"
                    color="green"
                    disabled={disabledState}
                    ref={registrarRef}
                    onFocus={() => registrarRef.current.blur()}
                  >
                    Registrar cliente
                  </StyledBoton>
                </div>
              </StyledCol>
            </StyledRow>
          </Form>
        </StyledContainer>

        {/* Formulario de precios del cliente */}
        <VentanaFormularioPrecios
          productos={productosCliente}
          mostrarPrecios={mostrarProductos}
          manejarCerrarVentana={() => setMostrarProductos(false)}
          manejarCambioPrecio={manejarCambioPrecio}
        />

        {/* Formulario de rutas del cliente */}
        <VentanaFormularioRuta
          // Estado del componente
          rutas={rutas}
          // Esto del Redux
          rutasRedux={rutasRedux}
          // Funciones para modificar el estado del componente
          modificarRuta={modificarRuta}
          modificarDayIds={modificarDayIds}
          // Mostrar ventana con rutas
          mostrarRutas={mostrarRutas}
          manejarCerrarVentana={() => setMostrarRutas(false)}
        />
      </>
    )
  );
};

export default RegistrarCliente;
