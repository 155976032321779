import {
  FAIL_RUTA_LISTA,
  REQUEST_RUTA_LISTA,
  SUCCESS_RUTA_LISTA,
} from "../constantes/rutaConstantes";
import axiosInstance from "../lib/axiosConfig";

// Creador de acciones para pedir las rutas del backend
export const pedirRutasParaClienteLista = () => async (dispatch) => {
  dispatch({ type: REQUEST_RUTA_LISTA });

  try {
    const { data } = await axiosInstance.get(`api/rutas-registrar-cliente/`);

    dispatch({ type: SUCCESS_RUTA_LISTA, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_RUTA_LISTA, payload: error.message });
  }
};
