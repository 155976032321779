import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import {
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
} from "../AjusteInventarioLista/styles/VentanaMostrarAjusteInventario.styles";
import { StyledButton } from "../general/styles/Boton.styles";

const VentanaMostrarAjusteInventario = ({
  ajusteInventario,
  mostrarModalAjusteInventario,
  manejarCerrarVentana,
}) => {

  //Referencia del boton
  const cerrarRef = useRef(null);

  return (
    ajusteInventario && (
      <Modal
        centered
        show={mostrarModalAjusteInventario}
        onHide={manejarCerrarVentana}
      >
        <StyledModalHeader>
          <h4>Detalles del ajuste inventario #{ajusteInventario.id}</h4>
        </StyledModalHeader>
        <StyledModalBody>
          <h5>Datos del ajuste inventario</h5>
          <p>
            <strong>CAJERO:</strong> {ajusteInventario.CAJERO}
          </p>
          <p>
            <strong>BODEGA:</strong> {ajusteInventario.BODEGA}
          </p>
          <p>
            <strong>PRODUCTO:</strong> {ajusteInventario.PRODUCTO_NOMBRE}
          </p>
          <p>
            <strong>CANTIDAD:</strong> {ajusteInventario.CANTIDAD}
          </p>
          <p>
            <strong>TIPO DE AJUSTE:</strong> {ajusteInventario.TIPO_AJUSTE}
          </p>
          <p>
            <strong>STATUS:</strong> {ajusteInventario.STATUS}
          </p>
          <p>
            <strong>OBSERVACIONES:</strong> {ajusteInventario.OBSERVACIONES}
          </p>
        </StyledModalBody>
        <StyledModalFooter>
          <StyledButton
            color={'blue'}
            ref={cerrarRef}
            onClick={() => {
              manejarCerrarVentana();
            }}
            onFocus={() => cerrarRef.current.blur()}
          >
            Cerrar
          </StyledButton>
        </StyledModalFooter>
      </Modal>
    )
  );
};

export default VentanaMostrarAjusteInventario;
