import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../componentes/general/Loader";
import { RESET_VENTA_DETALLES } from "../constantes/ventaConstantes";
import FiltroListaVentas from "../componentes/VentasLista/FiltroListaVentas";
import VentanaMostrarModalVenta from "../componentes/VentasLista/VentanaMostrarVenta";
import VentanaMostrarResumenVentas from "../componentes/VentasLista/VentanaMostrarResumenVentas";

import Mensaje from "../componentes/general/Mensaje";
import TablaVentas from "../componentes/VentasLista/TablaVentas";
import {
  StyledBoton,
  StyledBotonPanel,
  StyledContainer,
  StyledContenidoPrincipal,
  StyledGridContainer,
  StyledPanelControl,
  StyledTitulo,
} from "./styles/VentasLista.styles";
import PaginacionVentas from "../componentes/VentasLista/PaginacionVentas";
import {
  pedirVentasLista,
  descargarReporteVentas,
} from "../actions/ventaActions";
import { useMostrarModalVenta } from "../lib/hooks/useMostrarModalVenta";
import { useRef } from "react";
import axios from "axios";

const VentasLista = () => {
  // Funcion para disparar las acciones
  const dispatch = useDispatch();
  // Funcion para nevagar en la aplicacion
  const navigate = useNavigate();
  // Function para obtener el search param en el url
  const location = useLocation();
  const search = location.search;

  //Obtener referencias de los botones
  const descargarRef = useRef(null);
  const resumenRef = useRef(null);
  const panelMovilRef = useRef(null);

  // Obtener el estado desde el Redux store
  const ventaLista = useSelector((state) => state.ventaLista);
  const { loading, ventas, error, page, pages } = ventaLista;

  // Hook para mostrar y ocultar panel de control en pantallas pequenas
  const [mostrarFormulario, setMostrarFormulario] = useState(true);
  // Hook para mostrar el resumen de venta
  const [mostrarResumenVentas, setMostrarResumenVentas] = useState(false);

  // Funcion para navegar a la pagina del producto
  const manejarVentaDetalles = (productoId) => {
    // Redireccionar a la pagina de la venta
    dispatch({ type: RESET_VENTA_DETALLES });
    navigate(`/ventas/${productoId}`);
  };

  // Custom hook para mostrar los detalles de la venta
  const {
    mostrarVenta,
    venta,
    manejarCerrarVentana,
    manejarMostrarModalVenta,
  } = useMostrarModalVenta(ventas);

  // Si no hay ventas, disparar la accion de pedir ventas
  // useEffect(() => {
  //   const source = axios.CancelToken.source();

  //   if (!ventas) {
  //     dispatch(pedirVentasLista(search, source.token));
  //   }

  //   return () => {
  //     console.log("Cleaning up component");
  //     source.cancel("Request aborted my friend!");
  //   };
  // }, [dispatch, ventas, search]);

  // Si el parametros search con la informacion de filtrado se modifica, volver a pedir las ventas, INCLUDE SI YA EXISTEN LAS ventas
  // Como salgo y entro al componente cuando edito la venta el componente se vuelve a montar,
  // El problema es solo cuando eliminas algo y no sales del componente
  useEffect(() => {
    const source = axios.CancelToken.source();

    dispatch(pedirVentasLista(search, source.token));

    return () => {
      source.cancel("Petición lista ventas cancelada");
    };
  }, [search, dispatch]);

  const manejarExportarVentas = () => {
    dispatch(descargarReporteVentas(search));
  };

  if (loading)
    return (
      <Loader />
    );

  if (error)
    return (
      <StyledContainer>
        <Mensaje variant="danger">
          Hubo un problema al cargar la lista de ventas
        </Mensaje>
      </StyledContainer>
    );

  return (
    ventas && (
      <>
        <StyledGridContainer>
          <StyledBotonPanel
            color="standard"
            fontcolor="var(--active-blue-button)"
            ref={panelMovilRef}
            onFocus={() => panelMovilRef.current.blur()}
            onClick={() => setMostrarFormulario((state) => !state)}
            state={mostrarFormulario}
          >
            <i className="fa-solid fa-arrow-right" />
          </StyledBotonPanel>
          <StyledTitulo>Ventas</StyledTitulo>
          {/* Panel de control para filtrar y ordenar ventas */}
          <StyledPanelControl mostrarPanel={mostrarFormulario}>
            {/* <FiltroListaVentas manejarFiltros={manejarFiltros} /> */}
            <FiltroListaVentas />

            {/* Exportar ventas */}
            <div>
              <StyledBoton
                type="submit"
                color="green"
                disabled={false}
                ref={descargarRef}
                onFocus={() => descargarRef.current.blur()}
                onClick={() => manejarExportarVentas()}
              >
                Descargar tabla de ventas
              </StyledBoton>
            </div>

            {/* Mostrar resumen de ventas */}
            <div>
              <StyledBoton
                type="submit"
                color="green"
                disabled={false}
                ref={resumenRef}
                onFocus={() => resumenRef.current.blur()}
                onClick={setMostrarResumenVentas}
              >
                Mostrar resumen de ventas
              </StyledBoton>
            </div>
          </StyledPanelControl>

          {/* Tabla de ventas */}
          <StyledContenidoPrincipal>
            <TablaVentas
              ventas={ventas}
              manejarMostrarModalVenta={manejarMostrarModalVenta}
              manejarVentaDetalles={manejarVentaDetalles}
            ></TablaVentas>

            <PaginacionVentas
              page={page}
              pages={pages}
              search={search}
              isAdmin={false}
            />
          </StyledContenidoPrincipal>
        </StyledGridContainer>

        {/* Mostrar venta con detalles de la venta */}
        <VentanaMostrarModalVenta
          mostrarVenta={mostrarVenta}
          manejarCerrarVentana={manejarCerrarVentana}
          venta={venta}
        />

        {/* Mostrar resumen de ventas */}
        <VentanaMostrarResumenVentas
          mostrarResumenVentas={mostrarResumenVentas}
          manejarCerrarResumenVentas={() => setMostrarResumenVentas(false)}
          ventas={ventas}
        />
      </>
    )
  );
};

export default VentasLista;
