import React from "react";

import ImagenObjeto from "../general/ImagenObjecto";
import { useMediaQuery } from "react-responsive";
import {
  StyledButtonContainer,
  StyledDeleteButton,
  StyledGenericButton,
  TableStyled,
} from "./styles/TablaProductos.styles";
import { BASE_URL } from "../../constantes/constantes";
import { useRef } from "react";
import UseScreenSize from "../general/UseScreenSize";

const TablaProductos = ({
  productos,
  manejarMostrarDetallesProducto,
  manejarProductoDetalles,
  manejarRealizarAjusteInventario,
  manejarBorrarProducto,
}) => {
  // Determinar si el ancho de la pantalla es small o menor
  const isSmallViewport = useMediaQuery({ maxWidth: 768 });
  const shouldShow = !isSmallViewport;
  const { ancho, alto } = UseScreenSize();

  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  // Obtener referencia de los botones
  const invRef = useRef(null);
  const editRef = useRef(null);
  const delRef = useRef(null);

  // Renderizar tabla de productos
  return (
    <TableStyled striped hover>
      <thead>
        <tr>
          {shouldShow ? (
            <>
              <th>ID</th>
              <th>IMAGEN</th>
            </>
          ) : null}

          <th>NOMBRE</th>

          {shouldShow ? (
            <>
              <th>CANTIDAD</th>
              <th>PRECIO</th>
            </>
          ) : null}

          <th>EDITAR</th>

          {isAdmin && (
            <>
              {ancho > 400 ? (
                <th>AJUSTAR INVENTARIO</th>
              ) : ancho > 330 ? (
                <th>AJUSTAR INV.</th>
              ) : null}
              <th>BORRAR</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {productos.map((p) => (
          <tr key={p.id} onClick={() => manejarMostrarDetallesProducto(p.id)}>
            {shouldShow ? (
              <>
                <td>{p.id}</td>
                <td>
                  <ImagenObjeto src={`${BASE_URL}${p.IMAGEN}`} alt={p.NOMBRE} />
                </td>
              </>
            ) : null}

            <td>{p.NOMBRE}</td>

            {shouldShow ? (
              <>
                <td>{p.CANTIDAD.toFixed(2)}</td>
                <td>${p.PRECIO.toFixed(2)}</td>
              </>
            ) : null}

            <td>
              <StyledButtonContainer>
                {ancho <= 330 ? (
                  <StyledGenericButton
                    color="standard"
                    ref={invRef}
                    disabled={false}
                    onFocus={() => invRef.current.blur()}
                    onClick={() => manejarRealizarAjusteInventario(p.id)}
                  >
                    <i className="fa-solid fa-clipboard" />
                  </StyledGenericButton>
                ) : null}

                <StyledGenericButton
                  color="standard"
                  ref={editRef}
                  disabled={false}
                  onFocus={() => editRef.current.blur()}
                  onClick={() => manejarProductoDetalles(p.id)}
                >
                  <i className="fa-solid fa-pen-to-square" />
                </StyledGenericButton>
              </StyledButtonContainer>
            </td>
            {/* Solo un admin puede borrar un producto */}
            {isAdmin && (
              <>
                {ancho > 330 ? (
                  <td>
                    <StyledGenericButton
                      color="standard"
                      ref={invRef}
                      disabled={false}
                      onFocus={() => invRef.current.blur()}
                      onClick={() => manejarRealizarAjusteInventario(p.id)}
                    >
                      <i className="fa-solid fa-clipboard" />
                    </StyledGenericButton>
                  </td>
                ) : null}
                <td>
                  <StyledDeleteButton
                    ref={delRef}
                    color="red"
                    disabled={false}
                    onFocus={() => invRef.current.blur()}
                    variant="danger"
                    onClick={(e) => {
                      manejarBorrarProducto(e, p.id);
                    }}
                  >
                    <i className="fa-solid fa-trash" />
                  </StyledDeleteButton>
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </TableStyled>
  );
};

export default TablaProductos;
