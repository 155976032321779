import React from "react";
import { Form, Modal } from "react-bootstrap";
import {
  StyledBoton,
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
} from "./styles/VentanaFormularioPrecios.styles";
import { useRef } from "react";

const VentanaFormularioPrecios = ({
  productos,
  mostrarPrecios,
  manejarCerrarVentana,
  manejarCambioPrecio,
}) => {
  console.log("productos", productos);

  //Obtener referencia del boton
  const cerrarRef = useRef(null);

  return (
    productos && (
      <Modal centered show={mostrarPrecios} onHide={manejarCerrarVentana}>
        <StyledModalHeader>
          <h4>Precios del Cliente </h4>
        </StyledModalHeader>
        <StyledModalBody>
          <Form>
            {productos.map((p) => (
              <Form.Group
                controlId={p.NOMBRE ? p.NOMBRE : p.producto_nombre}
                key={p.id}
              >
                <Form.Label>
                  Producto: {p.NOMBRE ? p.NOMBRE : p.producto_nombre}
                </Form.Label>
                <Form.Control
                  type="number"
                  value={p.PRECIO}
                  onChange={(e) =>
                    manejarCambioPrecio(Number(e.target.value), Number(p.id))
                  }
                ></Form.Control>
              </Form.Group>
            ))}
          </Form>
        </StyledModalBody>
        <StyledModalFooter>
          <StyledBoton
            color="blue"
            disabled={false}
            ref={cerrarRef}
            onFocus={() => cerrarRef.current.blur()}
            variant="secondary"
            onClick={() => {
              manejarCerrarVentana();
            }}
          >
            Cerrar
          </StyledBoton>
        </StyledModalFooter>
      </Modal>
    )
  );
};

export default VentanaFormularioPrecios;
