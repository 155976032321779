import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import FormularioProductoVenta from "../componentes/RealizarVenta/FormularioProductoVenta";
import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";
import FormularioVenta from "../componentes/RealizarVenta/FormularioVenta";
import {
  StyledBotonPanel,
  StyledContainer,
  StyledContenidoPrincipal,
  StyledGridContainer,
  StyledPanelControl,
  StyledTitulo,
} from "./styles/RealizarVenta.styles";
import VentanaMostrarVenta from "../componentes/RealizarVenta/VentanaMostrarVenta";
import { useClienteVenta } from "../lib/hooks/useClienteVenta";
import { pedirClientesVentaLista } from "../actions/clienteActions";
import { useProductosVenta } from "../lib/hooks/useProductosVenta";
import axios from "axios";
import { useFiltroClientesVenta } from "../lib/hooks/useFiltroClientesVenta";

const RealizarVenta = () => {
  // Funcion para disparar las acciones
  const dispatch = useDispatch();

  // Referencia del boton
  const botonPMRef = useRef(null);

  // Obtener lista de clientes del Redux store
  const clienteVentaLista = useSelector((state) => state.clienteVentaLista);
  const { loading, clientesVenta, error } = clienteVentaLista;

  // Hooks para mostrar resultados de la venta
  const [mostrarVenta, setMostrarVenta] = useState(false);
  // const [imprimirTicket, setImprimirTicket] = useState(false);
  const [venta, setVenta] = useState({});
  // Hook para mostrar y ocultar panel de control en pantallas pequenas
  const [mostrarPanel, setMostrarPanel] = useState(true);

  // Hook personalizado para manejar el estado del cliente y su lista de precios
  const { cliente, manejarCambiarCliente, manejarCambiarProductosCliente } =
    useClienteVenta(clientesVenta);

  // Hook personalizado para manejar el estado de los productos de venta
  const {
    productosVenta,
    manejarLimpiarProductosVenta,
    manejarSeleccionarProducto,
    manejarCambioCantidad, // cantidad del producto de venta
    manejarConfirmarProducto,
    manejarCancelarProducto,
    isValid,
  } = useProductosVenta(
    cliente.productosCliente,
    manejarCambiarProductosCliente
  );

  const {
    searchQuery,
    searchQueryMessage,
    setFiltroClientes,
    manejarSearchQuery,
  } = useFiltroClientesVenta();

  // useEffect para pedir clientes
  useEffect(() => {
    const source = axios.CancelToken.source();

    let timeoutId;

    // If there is no search query request mostrador client. This is helpful when mounting the component to start with mostrador as the initial client
    if (!searchQuery) {
      dispatch(pedirClientesVentaLista("", source.token));

      setFiltroClientes((prevFiltroClientes) => ({
        ...prevFiltroClientes,
        searchQueryMessage:
          "Introduce al menos tres letras para iniciar la búsqueda del cliente",
      }));

      return;
    }

    if (searchQuery.length > 2) {
      timeoutId = setTimeout(() => {
        dispatch(pedirClientesVentaLista(searchQuery, source.token));

        setFiltroClientes((prevFiltroClientes) => ({
          ...prevFiltroClientes,
          searchQueryMessage: "",
        }));
      }, 1000);
    }

    return () => {
      source.cancel("Request has been canceled dude");
      clearTimeout(timeoutId);
    };
  }, [dispatch, searchQuery, setFiltroClientes]);

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  // Renderizar loading si se estan cargando los clientes
  if (loading)
    return (
      <Loader />
    );

  // Renderizar mensaje de error si el servidor regresa un error al pedir la lista de clientes
  if (error)
    return (
      <StyledContainer>
        <Mensaje variant="danger">
          Hubo un error al cargar la lista de clientes
        </Mensaje>
      </StyledContainer>
    );

  return (
    <>
      <StyledGridContainer>
        <StyledBotonPanel
          color="standard"
          fontcolor="var(--active-blue-button)"
          ref={botonPMRef}
          onFocus={() => botonPMRef.current.blur()}
          onClick={() => setMostrarPanel((state) => !state)}
          state={mostrarPanel}
        >
          <i className="fa-solid fa-arrow-right" />
        </StyledBotonPanel>
        <StyledTitulo>Realizar Venta</StyledTitulo>
        {/* Panel de control para registrar datos de venta */}
        <StyledPanelControl mostrarPanel={mostrarPanel}>
          <FormularioVenta
            cliente={cliente}
            manejarCambiarCliente={manejarCambiarCliente}
            productosVenta={productosVenta}
            manejarLimpiarProductosVenta={manejarLimpiarProductosVenta}
            clientesVenta={clientesVenta}
            manejarSeleccionarProducto={manejarSeleccionarProducto}
            productosCliente={cliente.productosCliente}
            setVenta={setVenta}
            setMostrarVenta={setMostrarVenta}
            deshabilitarVenta={!isValid}
            searchQuery={searchQuery}
            searchQueryMessage={searchQueryMessage}
            manejarSearchQuery={manejarSearchQuery}
          />
        </StyledPanelControl>
        {/* Contenido principal */}
        <StyledContenidoPrincipal>
          <FormularioProductoVenta
            productos={productosVenta}
            manejarCambioCantidad={manejarCambioCantidad}
            manejarConfirmarProducto={manejarConfirmarProducto}
            manejarCancelarProducto={manejarCancelarProducto}
          />
        </StyledContenidoPrincipal>
      </StyledGridContainer>

      {/* Mostrar venta */}
      {mostrarVenta && (
        <VentanaMostrarVenta
          venta={venta}
          mostrarVenta={mostrarVenta}
          setMostrarVenta={setMostrarVenta}
          productosVenta={productosVenta}
          cliente={cliente}
        />
      )}
    </>
  );
};

export default RealizarVenta;
