import { useState } from "react";

export const useClienteVenta = (clientesVenta = []) => {
  const [cliente, setCliente] = useState({
    id: "",
    nombre: "",
    productosCliente: [],
  });

  // Use effect para seleccionar cliente inicial y su lista de precios, y para fijar el vendedor
  // useEffect(() => {
  //   if (clientesVenta.length > 0) {
  //     setCliente({
  //       // Seleccionamos el primer cliente de la lista de clientes venta cuando se monta el componente
  //       nombre: clientesVenta[0].NOMBRE,
  //       productosCliente: clientesVenta[0].precios_cliente,
  //     });
  //   }
  // }, [clientesVenta]);

  // Cuando cambiamos el querysearch el cliente debe ser eliminado

  const manejarCambiarCliente = (clienteId) => {
    if (!clienteId) {
      setCliente({
        id: "",
        nombre: "",
        productosCliente: [],
      });

      return;
    }

    const clienteSeleccionado = {
      ...clientesVenta.find((c) => c.id === clienteId),
    };

    setCliente((prevCliente) => ({
      ...prevCliente, // el searchQuery permanece igual
      id: clienteId,
      nombre: clienteSeleccionado.NOMBRE,
      productosCliente: clienteSeleccionado.precios_cliente,
    }));
  };

  const manejarCambiarProductosCliente = (nuevosProductos) => {
    setCliente((prevCliente) => ({
      ...prevCliente,
      productosCliente: nuevosProductos,
    }));
  };

  return {
    cliente,
    manejarCambiarCliente,
    manejarCambiarProductosCliente,
  };
};
