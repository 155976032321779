import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  actualizarProducto,
  obtenerProductoDetalles,
} from "../actions/productoActions";
import {
  RESET_PRODUCTO_ACTUALIZAR,
  RESET_PRODUCTO_DETALLES,
} from "../constantes/productoConstantes";
import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";
import {
  StyledBoton,
  StyledCol,
  StyledContainer,
  StyledFormGroup,
  StyledRow,
} from "./styles/ProductoDetalles.styles";
import { useForm } from "react-hook-form";
import { useRef } from "react";

const ProductoDetalles = () => {
  // Obtener el id del producto
  const params = useParams();
  const productoId = params.id;

  // Funcion para disparar las acciones
  const dispatch = useDispatch();

  // Funcion para navegar en la pagina
  const navigate = useNavigate();

  // Estado deshabilitacion del boton
  const [disabledState, setDisabledState] = useState(false);

  // Obtener el estado desde el Redux store
  const productoDetalles = useSelector((state) => state.productoDetalles);
  const { loading, producto, error } = productoDetalles;

  // Obtener el estado desde el Redux store
  const productoActualizar = useSelector((state) => state.productoActualizar);
  const {
    loading: actualizarLoading,
    success: actualizarSuccess,
    error: actualizarError,
  } = productoActualizar;

  // Estado para la validar que cantidad sea mayor a cantidad inicial con no admins.
  const [cantidadInicial, setCantidadInicial] = useState(0);
  const [cantidad, setCantidad] = useState(0);
  const [cantidadIncrementar, setCantidadIncrementar] = useState(0);

  //Adquirir la referencia para los botones
  const RegresarButtonRef = useRef(null);
  const ActualizarButtonRef = useRef(null);
  const [updatePrice, setUpdatePrice] = useState(false);

  // useForm para validar el formulario
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // useEffect para mostrar las alertas de actualizar producto
  useEffect(() => {
    let toastId;
    if (actualizarLoading) {
      // toast.dismiss();
      toastId = toast.loading("Actualizando producto");
    }

    if (actualizarSuccess) {
      toast.dismiss(toastId);
      toast.success("Producto actualizado");
      // Reset producto actualizar para que no se ejecute este bloque de codigo cada vez que se entra a producto detalles
      dispatch({ type: RESET_PRODUCTO_ACTUALIZAR });
      navigate("/productos");
    }

    if (actualizarError) {
      toast.dismiss(toastId);
      toast.error("Error al actualizar");
    }
  }, [
    actualizarSuccess,
    actualizarError,
    actualizarLoading,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  // useEffect para mostrar las alertas de validacion del formulario
  useEffect(() => {
    if (errors.precio) {
      toast.dismiss();
      toast.error(errors.precio.message);
    }

    if (errors.nombre) {
      toast.dismiss();
      toast.error(errors.nombre.message);
    }
  }, [errors.nombre, errors.cantidad, errors.precio]);

  useEffect(() => {
    // Si no hay producto o el producto no es el que seleccione, disparar la accion de obtener producto
    // !producto: Fetch if the product is not yet loaded
    // producto.id !== Number(productoId): Fetch if the loaded product doesn't match the one you want
    if (!producto || producto.id !== Number(productoId)) {
      dispatch(obtenerProductoDetalles(productoId));
    } else {
      console.log("PRODUCTO", producto);
      // Establecer valores iniciales del formulario
      setValue("nombre", producto.NOMBRE);
      // setValue("cantidad", producto.CANTIDAD);
      setValue("precio", producto.PRECIO);

      // Establecer de cantidad inicial
      setCantidadInicial(Number(producto.CANTIDAD));
      setCantidad(Number(producto.CANTIDAD));
    }
  }, [dispatch, producto, productoId, actualizarSuccess, navigate, setValue]);

  const manejarActualizarProducto = (data) => {
    // Disparar la accion de actualizar producto
    setDisabledState(!disabledState);

    if (!isAdmin && cantidad < cantidadInicial) {
      toast.dismiss();
      toast.error(
        `Solo un administrador puede reducir el número de productos. Por favor ingresa un valor superior a la cantidad inicial ${cantidadInicial}`
      );
    } else {
      const formData = new FormData();


      formData.append("NOMBRE", data.nombre);

      formData.append("PRECIO", data.precio);

      if (data.imagen[0]) {
        formData.append("IMAGEN", data.imagen[0]);
      }

      if (cantidadIncrementar) {
        formData.append("CANTIDAD", cantidad + cantidadIncrementar);
      } else {
        formData.append("CANTIDAD", cantidad || 0);
      }

      if (updatePrice) {
        formData.append("update_price", updatePrice);
        formData.append("productoId", producto.id);
      }

      // Print formData data
      // for (const entry of formData.entries()) {
      //   console.log(entry[0], entry[1]);
      // }
      dispatch(actualizarProducto(productoId, formData));
    }
  };

  const manejarRegresar = () => {
    // Redireccionar a la pagina de productos
    dispatch({ type: RESET_PRODUCTO_DETALLES });
    navigate("/productos");
  };

  const manejarCambiarcantidad = (nuevaCantidad) => {
    if (nuevaCantidad <= 0) {
      nuevaCantidad = "";
    }

    setCantidad(nuevaCantidad);
  };

  const manjarIncrementarCantidad = (nuevoIncrementarCantidad) => {
    if (nuevoIncrementarCantidad <= 0) {
      nuevoIncrementarCantidad = "";
    } else {
      // setCantidad((prevCant) => prevCant + nuevoIncrementarCantidad);
    }
    setCantidadIncrementar(nuevoIncrementarCantidad);
  };

  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
  // const isAdmin = false;

  // Renderizar loading si se estan cargando la informacion del producto
  if (loading) return <Loader />;

  // Renderizar mensaje de errors si el servidor regresa un error al pedir la informacion del producto
  if (error)
    return (
      <StyledContainer fluid>
        <StyledRow style={{ height: "80%" }}>
          <StyledCol>
            <Mensaje variant="danger">
              Hubo un error al cargar la informacion del producto
            </Mensaje>
          </StyledCol>
        </StyledRow>
      </StyledContainer>
    );

  if (isAdmin && producto)
    return (
      <StyledContainer fluid>
        <h1>Producto #{producto.id}</h1>
        <Row>
          <StyledCol>
            <StyledBoton
              color="green"
              ref={RegresarButtonRef}
              disabled={false}
              onFocus={() => RegresarButtonRef.current.blur()}
              onClick={manejarRegresar}
            >
              Regresar
            </StyledBoton>
          </StyledCol>
        </Row>
        <Form onSubmit={handleSubmit(manejarActualizarProducto)}>
          <StyledRow>
            <StyledCol md={6}>
              <StyledFormGroup controlId="nombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  {...register("nombre", {
                    required: "Por favor, introduce el nombre del producto",
                  })}
                  type="text"
                  autoComplete="off"
                ></Form.Control>
              </StyledFormGroup>

              <StyledFormGroup controlId="cantidad">
                <Form.Label style={{ color: "white", fontWeight: "bold" }}>
                  Cantidad
                </Form.Label>
                <Form.Control
                  style={{ color: "black", fontWeight: "bold" }}
                  // {...register("cantidad", {
                  //   required: "Por favor, introduce la cantidad de producto",
                  // })}
                  value={cantidad}
                  onChange={(e) =>
                    manejarCambiarcantidad(Number(e.target.value))
                  }
                  type="number"
                  step="any"
                  disabled={cantidadIncrementar}
                ></Form.Control>
              </StyledFormGroup>

              <StyledFormGroup controlId="incrementarCantidad">
                <Form.Label style={{ color: "white", fontWeight: "bold" }}>
                  Incrementar Cantidad
                </Form.Label>
                <Form.Control
                  style={{ color: "black", fontWeight: "bold" }}
                  value={cantidadIncrementar}
                  onChange={(e) =>
                    manjarIncrementarCantidad(Number(e.target.value))
                  }
                  type="number"
                  step="any"
                ></Form.Control>
              </StyledFormGroup>
              <p className="text-light fs-6 text-center">
                (Cantidad con incremento: {cantidad + cantidadIncrementar})
              </p>
            </StyledCol>

            <StyledCol md={6}>
              <StyledFormGroup controlId="precio">
                <Form.Label>Actualizar precio</Form.Label>
                <Form.Check
                  checked={updatePrice}
                  onClick={(e) => setUpdatePrice(!updatePrice)}
                ></Form.Check>
              </StyledFormGroup>

              <StyledFormGroup controlId="precio">
                <Form.Label>Precio</Form.Label>
                <Form.Control
                  {...register("precio", {
                    required: "Por favor, introduce el precio del producto",
                  })}
                  type="number"
                  step="any"
                ></Form.Control>
              </StyledFormGroup>

              <StyledFormGroup controlId="imagen">
                <Form.Label>Imagen</Form.Label>

                <Form.Control {...register("imagen")} type="file" />
              </StyledFormGroup>
            </StyledCol>
          </StyledRow>
          <Row>
            <StyledCol>
              <StyledBoton
                type="submit"
                color="green"
                ref={ActualizarButtonRef}
                disabled={disabledState}
                onFocus={() => ActualizarButtonRef.current.blur()}
              >
                Actualizar producto
              </StyledBoton>
            </StyledCol>
          </Row>
        </Form>
      </StyledContainer>
    );

  if (producto)
    return (
      <StyledContainer fluid>
        <StyledRow>
          <StyledCol>
            <h1>Producto #{producto.id}</h1>
            <StyledBoton
              color="green"
              ref={RegresarButtonRef}
              disabled={false}
              onFocus={() => RegresarButtonRef.current.blur()}
              onClick={manejarRegresar}
            >
              Regresar
            </StyledBoton>
          </StyledCol>
        </StyledRow>

        <Form onSubmit={handleSubmit(manejarActualizarProducto)}>
          <StyledRow className="d-flex justify-content-center">
            <StyledCol md={6}>
              <StyledFormGroup controlId="incrementarCantidad">
                <Form.Label style={{ color: "white", fontWeight: "bold" }}>
                  Incrementar Cantidad
                </Form.Label>
                <Form.Control
                  style={{ color: "black", fontWeight: "bold" }}
                  value={cantidadIncrementar}
                  onChange={(e) =>
                    manjarIncrementarCantidad(Number(e.target.value))
                  }
                  type="number"
                  step="any"
                ></Form.Control>
              </StyledFormGroup>
              <p className="text-light fs-6 text-center">
                (Cantidad con incremento: {cantidad + cantidadIncrementar})
              </p>
              <StyledFormGroup controlId="imagen">
                <Form.Label>Imagen</Form.Label>

                <Form.Control {...register("imagen")} type="file" />
              </StyledFormGroup>
            </StyledCol>
          </StyledRow>

          <StyledRow>
            <StyledCol>
              <StyledBoton
                type="submit"
                color="green"
                ref={ActualizarButtonRef}
                disabled={disabledState}
                onFocus={() => ActualizarButtonRef.current.blur()}
              >
                Actualizar producto
              </StyledBoton>
            </StyledCol>
          </StyledRow>
        </Form>
      </StyledContainer>
    );
};

export default ProductoDetalles;
