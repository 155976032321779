import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import {
  StyledBoton,
  StyledButtonContainer,
  StyledLockContainer,
  TableStyled,
} from "./styles/TablaVentas.styles";
import { getFormatoFecha, getFormatoHora } from "../../lib/utilis/venta";
import { truncateNombre } from "../../lib/utilis/clientes";
import UseScreenSize from "../general/UseScreenSize";
import { useRef } from "react";

const TablaVentas = ({
  ventas,
  manejarMostrarModalVenta,
  manejarVentaDetalles,
}) => {
  const isSmallViewport = useMediaQuery({ maxWidth: 768 });
  const shouldshow = !isSmallViewport;
  const { alto, ancho } = UseScreenSize();

  //Obtener referencia del boton
  const editarRef = useRef(null);

  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  return (
    <Container>
      <Row>
        <Col>
          <TableStyled striped hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>CLIENTE</th>

                {ancho > 500 ? <th>FECHA</th> : null}
                {ancho > 700 ? <th>VENDEDOR</th> : null}
                {ancho > 1200 ? <th>HORA</th> : null}
                {ancho > 500 ? <th>TIPO DE VENTA</th> : null}
                {ancho > 1100 ? <th>TIPO DE PAGO</th> : null}
                {ancho > 1200 ? <th>STATUS</th> : null}

                {isAdmin ? <th>EDITAR</th> : null}
              </tr>
            </thead>
            <tbody>
              {ventas.map((venta) => (
                <tr
                  key={venta.id}
                  onClick={() => {
                    return manejarMostrarModalVenta(venta.id);
                  }}
                >
                  {<td>{venta.id}</td>}
                  {
                    <td>
                      {venta.NOMBRE_CLIENTE
                        ? truncateNombre(venta.NOMBRE_CLIENTE)
                        : "NO DISPONIBLE"}
                    </td>
                  }

                  {ancho > 500 ? <td>{getFormatoFecha(venta.FECHA)}</td> : null}
                  {ancho > 700 ? (
                    <td>{truncateNombre(venta.VENDEDOR)}</td>
                  ) : null}
                  {ancho > 1200 ? <td>{getFormatoHora(venta.FECHA)}</td> : null}
                  {ancho > 500 ? <td>{venta.TIPO_VENTA}</td> : null}
                  {ancho > 1100 ? <td>{venta.TIPO_PAGO}</td> : null}
                  {ancho > 1200 ? <td>{venta.STATUS}</td> : null}

                  {isAdmin ? (
                    <td>
                      {venta.STATUS === "PENDIENTE" || venta.STATUS == "REALIZADO" ? (
                        <StyledButtonContainer>
                          <StyledBoton
                            color="standard"
                            fontcolor="var(--active-blue-button)"
                            ref={editarRef}
                            onFocus={() => editarRef.current.blur()}
                            shouldshow={shouldshow.toString()}
                            onClick={() => manejarVentaDetalles(venta.id)}
                          >
                            <i className="fa-regular fa-pen-to-square" />
                          </StyledBoton>
                        </StyledButtonContainer>
                      ) : (
                        <StyledButtonContainer>
                          <StyledLockContainer color="standard" disabled={true}>
                            <i className="fa-solid fa-lock" />
                          </StyledLockContainer>
                        </StyledButtonContainer>
                      )}
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </TableStyled>
        </Col>
      </Row>
    </Container>
  );
};

export default TablaVentas;
